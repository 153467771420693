import React, { FC } from 'react';
import Video from 'components/Video';

import { IInterstitialComponentProps } from 'components/Interstitial/models';
import 'components/Interstitial/Interstitial.scss';
import { DangerouslySetInnerHtml } from 'layout';

const Interstitial: FC<IInterstitialComponentProps> = (model) => {
  return (
    <div className="dt-interstitial">
      <h2 className="dt-interstitial__title">{model.properties.title}</h2>
      {model.properties.video && model.properties.video[0] ? (
        <Video videoURL={model.properties.video[0].properties.videoLink} />
      ) : null}

      <DangerouslySetInnerHtml html={model.properties.content} />
      {model.properties.image && model.properties.image[0] ? (
        <div className="dt-interstitial__overlay-container">
          <img
            src={model.properties.image[0].properties.image.fallbackUrl}
            alt={model.properties.image[0].properties.imageAltLabel}
          />
          <div className="dt-interstitial__overlay">
            <h2 className="dt-interstitial__overlay-title">{model.properties.overlayTitle}</h2>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Interstitial;
