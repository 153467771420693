import React, { FC, useState } from 'react';
import { graphql, Link } from 'gatsby';
import classnames from 'classnames';

import { DangerouslySetInnerHtml, Layout } from 'gatsby-theme-husky/src/layout';
import ProductTitle from 'gatsby-theme-husky/src/components/ProductTitle';
import ProductFeatures from 'gatsby-theme-husky/src/components/ProductFeatures';
import ProductDescription from 'gatsby-theme-husky/src/components/ProductDescription';
import ProductAdvantages from 'gatsby-theme-husky/src/components/ProductAdvantages';
import ProductCarousel from 'gatsby-theme-husky/src/components/ProductCarousel';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import ReviewStars from 'gatsby-theme-husky/src/components/ReviewStars';
import BazaarvoiceStars from 'gatsby-theme-husky/src/components/BazaarvoiceStars';
import BazaarvoiceReviews from 'gatsby-theme-husky/src/components/BazaarvoiceReviews';
import Breadcrumbs from 'gatsby-theme-husky/src/components/Breadcrumbs';
import RelatedProducts from 'gatsby-theme-husky/src/components/RelatedProducts';
import { IProductPageProps } from 'gatsby-theme-husky/src/templates/ProductPage/model';
import ProductReview from 'gatsby-theme-husky/src/components/ProductReview';
import TRANSLATES from 'gatsby-theme-husky/src/shared/static/translates';
import Button from 'gatsby-theme-husky/src/common/Button';
import IconButton from 'gatsby-theme-husky/src/common/IconButton';
import IconTextButton from 'gatsby-theme-husky/src/common/IconTextButton';
import BuyNowDropdown from 'gatsby-theme-husky/src/components/BuyNowDropdown';
import Interstitial from '../../components/Interstitial';

import 'gatsby-theme-husky-secondary/src/gatsby-theme-husky/templates/ProductPage/ProductPage.scss';
import './ProductPageExtends.scss';
import 'gatsby-theme-husky/src/templates/ProductPage/ProductPageRTL.scss';

const ProductPage: FC<IProductPageProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoProducts: {
      defaultCompositions,
      properties: {
        seoMetaTitle,
        seoCanonicalUrl,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        title,
        amountCondoms,
        productAdvantages,
        productDescriptionText,
        productFeatures,
        image,
        turnBackPDPText,
        relatedProductsCardCtaButton,
        relatedProductsCtaButtonShow,
        relatedProductsTitle,
        relatedProductsCardCTAButtonAriaLabel,
        relatedProductsCtaButton,
        buttons,
        buttonsLayoutDirection,
        sku,
        ean,
        reviewsTitle,
        buyNowDisclaimer,
        buyNow,
        interstitialContent,
      },
      url,
    },
    relatedProducts,
  },
}) => {
  const [reviewWrapperOffSetTop, setReviewWrapperOffSetTop] = useState<number>(0);

  const { isMobile } = useScreenRecognition();
  const urlToCategory = url
    .split('/')
    .filter((link) => link && !TRANSLATES.LANGUAGES.includes(link));
  const topBannerCategory = urlToCategory[urlToCategory.length - 2]
    .replace(/-/g, ' ')
    .toUpperCase();
  const topBannerCategoryLink = `/${urlToCategory.slice(0, -1).join('/')}`;

  const {
    commonSettings: { showReviewPanel, showRelatedProduct },
  } = defaultCompositions;

  const isReviewPanelVisible = !!Number(showReviewPanel);
  const isRelatedProductVisible = !!Number(showRelatedProduct);

  const openGraphImage = image?.[0]?.desktopImage?.childImageSharp?.fluid.src;

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      headerTransparent
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoCanonicalUrl,
        seoExternalHreflangs,
        openGraphImage,
      }}
    >
      {!isMobile && isMobile !== null ? (
        <Breadcrumbs url={url} className="product__breadcrumbs" />
      ) : (
        <div className="product__breadcrumbs--mobile">
          <Link to={topBannerCategoryLink}>
            <div className="icon-arrow-left" />
            <span>{turnBackPDPText || topBannerCategory}</span>
          </Link>
        </div>
      )}
      <div className="product-page">
        <div className="product-banner">
          {isMobile ? (
            <ProductTitle title={title} amountCondoms={amountCondoms} classes="productTitleOrder" />
          ) : null}
          <ProductCarousel
            isMobile={isMobile}
            images={image}
            isFirstViewport
            classes="product-carousel"
          />
          <div className="product-right-pack">
            {!isMobile ? (
              <>
                <ProductTitle
                  title={title}
                  amountCondoms={amountCondoms}
                  classes="productTitleOrder"
                />
                {isReviewPanelVisible ? (
                  <ReviewStars className="product-reviews" sku={sku} />
                ) : null}
              </>
            ) : null}
            {ean ? (
              <div className="bazarvoice-container">
                <BazaarvoiceStars {...{ ean, reviewWrapperOffSetTop }} />
              </div>
            ) : null}
            <div
              className={classnames('product-buttons', {
                'product-buttons--column': buttonsLayoutDirection?.[0] === 'column',
              })}
            >
              {isReviewPanelVisible && isMobile ? (
                <ReviewStars className="product-reviews" sku={sku} />
              ) : null}
              {buttons?.map(
                ({
                  properties: {
                    iconButtonAriaLabel,
                    iconButtonUrl,
                    iconButtonImage,
                    iconButtonImageAlt,
                    buttonText,
                    buttonLink,
                    iconTextButtonTitle,
                  },
                }) =>
                  iconTextButtonTitle ? (
                    <IconTextButton
                      iconTextButtonTitle={iconTextButtonTitle}
                      ariaLabel={iconButtonAriaLabel}
                      link={iconButtonUrl?.[0].url}
                      key={iconButtonAriaLabel}
                      iconButtonImage={iconButtonImage}
                      iconButtonImageAlt={iconButtonImageAlt}
                    />
                  ) : iconButtonUrl ? (
                    <IconButton
                      ariaLabel={iconButtonAriaLabel}
                      link={iconButtonUrl?.[0].url}
                      key={iconButtonAriaLabel}
                      iconButtonImage={iconButtonImage}
                      iconButtonImageAlt={iconButtonImageAlt}
                    />
                  ) : buttonText ? (
                    <Button
                      key={buttonText}
                      ariaLabel={buttonText}
                      link={buttonLink?.[0].url}
                      btnColorVariant="dark"
                      className="product-buttons-styles"
                    >
                      {buttonText}
                    </Button>
                  ) : null
              )}
              {buyNow?.[0] ? <BuyNowDropdown buyNow={buyNow} /> : null}
            </div>
            {buyNowDisclaimer ? (
              <DangerouslySetInnerHtml html={buyNowDisclaimer} className="product-disclaimer" />
            ) : null}
          </div>
        </div>

        {/* Map through the aPlusContent and display each one */}
        {interstitialContent &&
          interstitialContent
            .filter((item) => item.properties.position.includes('top'))
            .map((aPlus) => <Interstitial properties={aPlus.properties} />)}

        {productFeatures && productFeatures.length > 0 ? (
          <ProductFeatures productFeatures={productFeatures} />
        ) : null}
        {productDescriptionText ? (
          <ProductDescription productDescriptionText={productDescriptionText} />
        ) : null}

        {/* Map through the aPlusContent and display each one */}
        {interstitialContent &&
          interstitialContent
            .filter((item) => item.properties.position.includes('middle'))
            .map((aPlus) => <Interstitial properties={aPlus.properties} />)}

        {ean ? (
          <div className="bazarvoice-container">
            <BazaarvoiceReviews {...{ ean, reviewsTitle, setReviewWrapperOffSetTop }} />
          </div>
        ) : null}
      </div>

      {productAdvantages ? <ProductAdvantages productAdvantages={productAdvantages} /> : null}

      {isReviewPanelVisible ? <ProductReview sku={sku} /> : null}
      {interstitialContent &&
        interstitialContent
          .filter((item) => item.properties.position.includes('bottom'))
          .map((aPlus) => <Interstitial properties={aPlus.properties} />)}
      {isRelatedProductVisible ? (
        <div className="related-products__wrapper">
          <RelatedProducts
            relatedProducts={relatedProducts}
            relatedProductsCardCtaButton={relatedProductsCardCtaButton}
            relatedProductsCtaButtonShow={relatedProductsCtaButtonShow}
            relatedProductsTitle={relatedProductsTitle}
            relatedProductsCardCTAButtonAriaLabel={relatedProductsCardCTAButtonAriaLabel}
            relatedProductsCtaButton={relatedProductsCtaButton}
          />
        </div>
      ) : null}

      {isReviewPanelVisible ? (
        <div
          className="yotpo yotpo-main-widget"
          data-product-id={sku}
          data-name={title}
          data-url={url}
        />
      ) : null}
    </Layout>
  );
};
export const query = graphql`
  query ProductPageAU($url: String!, $links: [String]) {
    umbracoProducts(url: { eq: $url }) {
      url
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      properties {
        ...FragmentProductPageProperties
        seoExternalHreflangs {
          hreflang: key
          href: value
        }
        sku
        ean
        reviewsTitle
        turnBackPDPText
        title
        amountCondoms
        buttons {
          properties {
            ...FragmentIconButton
            ...FragmentCommonButton
            ...FragmentIconTextButton
          }
        }
        buyNow {
          properties {
            triggerButtonTitle
            triggerButtonAriaLabel
            dropdownTitle
            closeButtonTitle
            closeButtonAriaLabel
            shops {
              properties {
                ...FragmentIconButton
              }
            }
          }
        }
        buttonsLayoutDirection
        image {
          properties {
            imageAltLabel
          }
          mobileImage: localImage {
            childImageSharp {
              fluid(maxWidth: 250, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: localImage {
            childImageSharp {
              fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        productDescriptionText {
          properties {
            descriptionText
            descriptionTitle
          }
        }
        productFeatures {
          properties {
            productFeaturesText {
              value
            }
            productTextTitle
          }
        }
        buyNowDisclaimer
        interstitialContent {
          properties {
            title
            image {
              properties {
                imageAltLabel
                image {
                  fallbackUrl
                }
              }
            }
            position
            video {
              properties {
                videoLink
              }
            }
            content
            overlayTitle
          }
        }
      }
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $links } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid(
                  maxWidth: 380
                  quality: 90
                  sizes: "(max-width: 767px) 50vw, (max-width: 991px) 178px, 250px"
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
    umbracoHome(url: { eq: "/" }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default ProductPage;
